const page = {
  state: {
    now_router:''
  },

  mutations: {
    SET_ROUTER: (state, now_router) => {
      state.now_router = now_router
    }
  },

  actions: {
    // 保存将要展示的页面path
    saveNextPath({ commit }, to_router) {
      // const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        if(to_router){

          commit('SET_ROUTER', to_router)
          resolve()
        }else{

          reject('getInfo: roles must be a non-null array !')
        }

      })
    }
  }
}

export default page