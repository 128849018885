import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './style/index.scss'

import jQuery from 'jquery'

import router from './router'
import store from './store'
import './permission' // permission control

Vue.use(Element, {
  size: 'medium' // set element-ui default size
  // i18n: (key, value) => i18n.t(key, value) // ======================================
})

new Vue({
  render: h => h(App),
  store,
  router,
  jQuery
}).$mount('#app')
