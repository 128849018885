<template>
  <div class="footInfoWrapper">
    <el-row class="detailInfo">
      <!-- 公司名称和专利信息 -->
      <el-col :span="8" class="basicCol">
        <div class="basicInfoDiv">
          <div class="imgInfo" v-if="false"></div>
          <div class="companyName">
            <p>上海出海通网络技术有限公司</p>
            <p>SHANGHAI CHU HAI TONG NETWORK TECHNOLOGY LIMITED</p>
          </div>
        </div>
        <div class="policyInfo">
          <span class="policyItem">服务政策</span>
          <span class="policyItem">隐私政策</span>
        </div>
        <!-- <div class="copyrightInfo">Copyright © 2016 Perfect Balance,hr@pbtccn.com</div> -->
      </el-col>
      <!-- 公司电话地址等 -->
      <el-col :span="8" class="phoneCol">
        <h4>联系我们</h4>
        <p>邮箱：purchase@pbtccn.com</p>
        <p>电话：021-50654812     021-50654810</p>
        <p>地址：上海市奉贤区奉浦大道1599号M幢<br/>Building M, No.1599, Fengpu Avenue, Fengxian District, Shanghai</p>
      </el-col>
      <!-- 友情链接 -->
      <el-col :span="8" class="linkCol">
        <h4>友情链接</h4>
        <el-row class="linkList">
          <a class="linkItem" target="_blank" href="https://www.ebay.com">ebay</a>
          <a class="linkItem" target="_blank" href="https://www.onbuy.com/gb/">onbuy</a>
          <br/>
          <a class="linkItem" target="_blank" href="https://www.amazon.com">amazon</a>
          <a class="linkItem" target="_blank" href="https://www.aliexpress.com/">aliexpress</a>
          <br/>
          <a class="linkItem" target="_blank" href="https://www.wish.com">wish</a>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .footInfoWrapper{

    .detailInfo{
      margin-top: 40px;
      background: lightgray;
      padding: 20px;

      .basicCol{
        padding-left: 60px;

        .basicInfoDiv{
          display: flex;

          .imgInfo{
            width: 40px;
            height: 40px;
          }

          .companyName{
            p{
              margin: 0;
              &:nth-child(1){
                font-size: 20px;
                color: #03cffd;
                font-weight: bold;
              }
              &:nth-child(2){
                font-size: 16px;
                color: #03cffd;
                font-weight: bold;
              }
            }
          }
          
        }

        .policyInfo{
          margin-top: 85px;
          display: flex;
          // justify-content: space-between;
          .policyItem+.policyItem{
            margin-left: 100px;
          }
        }

        .copyrightInfo{
          margin-top: 20px;
          text-align: left;
        }
        
      }

      .phoneCol{

        h4{
          margin-top: 0;
        }

        h4,p{
          text-align: left;
        }
        
      }

      .linkCol{

        h4{
          margin-top: 0;
        }

         h4,.linkList{
          text-align: left;
         }

         .linkList{
          //  display: flex;

          .linkItem{
            color:#2c3e50;
            text-decoration: none;
            width: 80px;
            display: inline-block;
            
            &+.linkItem{
              margin-left: 80px;
            }

            &:nth-child(n+3){
              margin-top: 30px;
            }
            
          }
           
         }

      }
      
    }
    
  }
</style>