<template>
  <div class="mainPageWrapper">
    <!-- 头部导航条 -->
    <el-row class="headerRow">
      <!-- 图片和系统名 -->
      <el-col :span="8" class="companyCol">
        <div class="topImgDiv" v-if="false">图片</div>
        <div class="siteInfo">
          <p>出海通开放平台</p>
          <!-- <p>SHANGHAI CHU HAI TONG NETWORK TECHNOLOGY LIMITED</p> -->
          <!-- <p>API开放平台</p> -->
        </div>
      </el-col>
      <el-col :span="16" class="operateCol">
        <ul class="itemList">
          <li class="itemLi" :class="{ active: nowRoute === '/index'}"><router-link to="/index" name="index">首页</router-link></li>
          <li class="itemLi" :class="{ active: /\/instruction/.test(nowRoute) }"><router-link to="/instruction" name="instruction">操作文档</router-link></li>
          <li class="itemLi" :class="{ active: nowRoute === '/concact'}"><a @click="scrollToBottom">联系我们</a></li>
          <li class="itemLi" :class="{ active: nowRoute === '/login'}"><router-link to="/login" name="login">登录</router-link></li>
          <li class="itemLi" :class="{ active: nowRoute === '/register'}"><router-link to="/register" name="register">注册</router-link></li>
          <li class="itemLi">
            <el-dropdown>
              <span class="el-dropdown-link">
                界面语言<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>中文简体</el-dropdown-item>
                <el-dropdown-item>中文繁体</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </el-col>
    </el-row>
    <!-- 内容区域 -->
    <el-row class="contentRow">
      <router-view></router-view>
    </el-row>
    <!-- 底部信息 -->
    <el-row class="footRow">
      <footinfo></footinfo>
    </el-row>

    <!-- 滚动到顶部按钮 -->
    <div class="toTopDiv" v-if="showToTop" @click="scrollToTop">
      <i class="el-icon-caret-top"></i>
    </div>
    
  </div>
</template>

<script>
// import $ from 'jquery'
import footinfo from './panel/footInfo'

export default {
  name:'mainPage',
  components: { footinfo },
  data(){
    return {
      activeItem: 'index',
      showToTop: true
    }
  },
  mounted(){
    this.initPage()
  },
  methods:{
    // 初始化页面
    initPage(){
      this.bindScrollEvent()
    },
    bindScrollEvent(){
      window.onscroll = () => {
        this.showToTop = window.scrollY > 150
      }
    },
    //滚动到页面底部
    scrollToBottom(){
      const height = document.body.scrollHeight
      window.scrollTo({ top: height})
      // $(document.body).animate({top: height})

      let index = 0
      let perY = height/30

      const timer =  window.requestAnimationFrame( function fn() {
        if(index < 30){
          window.scrollTo({ top: perY * (index +1) })
          index++
          window.requestAnimationFrame(fn)
        }else{
          window.cancelAnimationFrame(timer)
        }
      })

    },
    //滚动到页面顶部
    scrollToTop(){
      let nowTop = window.scrollY
      let index = 0
      let perY = nowTop/30
      
      const timer =  window.requestAnimationFrame( function fn() {
        if(index < 30){
          window.scrollTo({ top: nowTop - perY * (index +1) })
          index++
          window.requestAnimationFrame(fn)
        }else{
          window.cancelAnimationFrame(timer)
        }
      })
    }
  },
  computed:{
    //获取当前的页面路由
    nowRoute:{
      set(){},
      get(){
        return this.$store.state.page.now_router
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mainPageWrapper{
    width: 100%;
    overflow-x: hidden;
    
    .headerRow{
      position: fixed;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 10px;
      box-shadow: 0 1px 10px rgba(0,0,0,0.1);
      background-color: #fff;
      z-index: 5;

      .companyCol{
        display: flex;
        padding-left: 80px;

        .topImgDiv{
          width: 40px;    
          height: 40px;
        }
        .siteInfo{
          padding-left: 12px;
          p{
            margin: 0;
            &:nth-child(1){
              font-size: 16px;
              font-weight: bold;
              color: #03cffd;
            }
            &:nth-child(2){
              font-size: 12px;
              font-weight: bold;
              color: #03cffd;
            }
            &:nth-child(3){
              font-size: 14px;
              font-weight: bold;
              color: #03cffd;
            }
          }
        }
        
      }

      .operateCol{

        .itemList{
          list-style: none;
          margin: 0;
          display: flex;

          .itemLi{
            margin-left: 40px;
            color:#333;
            a{
              padding: 20px;
              color:#333;

              text-decoration: none;
              cursor: pointer;
            }

            &:hover{
              a{
                color: #03cffd;
              }
            }

            &.active{

              a{
                position: relative;
  
                &::before{
                  content: '';
                  position:absolute;
                  bottom: 0;
                  left: 0;
                  height: 4px;
                  width: 100%;
                  border-radius: 2px;
                  background: #03cffd;
                }
              }
              
            }
            
          }

        }
        
      }

    }
    
    .contentRow{
      margin-top: 84px;
    }

    .toTopDiv{
      position: fixed;
      right: 50px;
      bottom: 70px;
      background-color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #409eff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      z-index: 5;

      &:hover{
        background-color: #f2f6fc;
      }

    }
    
  }
</style>